<template>
  <div style="position: relative">
    <b-form-group
      v-if="def"
      :class="{ required: def.Mandatory == 1, 'mt-4': true }"
      :id="groupId"
      :label="def.Title"
      :label-for="inputId"
      :description="def.Description">

      <div
        v-if="inputType == 'readonly'"
        :id="inputId">
        {{ textValue }}
      </div>

      <b-form-datepicker
        v-if="inputType == 'datepicker'"
        :id="inputId"
        v-model="textValue"
        />

      <b-form-input
        v-if="inputType == 'text'"
        :id="inputId"
        :ref="inputId"
        v-model="textValue"
        type="text"
        :disabled="readonly || disabled"
        :required="def.Mandatory == 1"
      />

      <b-form-select
        v-if="inputType == 'select'"
        :id="inputId"
        v-model="textValue"
        :options="selectOptions"
        :required="def.Mandatory == true"
      />

      <!-- Tags -->
      <b-form-select
        v-if="inputType == 'tag-select' && def.MaxOccurs == 1"
        :id="inputId"
        v-model="tagValue"
        :options="selectOptions"
        :required="def.Mandatory == true"
      />

      <b-form-textarea
        v-if="inputType == 'tag-text' && def.MaxOccurs > 1"
        :id="inputId"
        v-model="tagText"
        :required="def.Mandatory == true"
      />

      <vue-multiselect
        v-if="inputType == 'tag-select' && def.MaxOccurs > 1"
        :multiple="true"
        :id="inputId"
        label="text"
        track-by="value"
        placeholder="Kies één of meer opties"
        v-model="tagValue"
        :options="selectOptions"
        :required="def.Mandatory == true"
      />

      <!-- Attributes -->
      <b-form-datepicker
        v-if="inputType == 'attribute-datepicker'"
        :id="inputId"
        v-model="attributeValue"
        />

      <b-form-input
        v-if="inputType == 'attribute-text'"
        :id="inputId"
        v-model="attributeValue"
        type="text"
        :required="def.Mandatory == 1"
      />

      <b-form-select
        v-if="inputType == 'attribute-select'"
        :id="inputId"
        v-model="attributeValue"
        :options="selectOptions"
        :required="def.Mandatory == true"
      />

    </b-form-group>
    <a class="kblink" :href="def.KBLink" v-if="def.KBLink" target="_blank">Meer Informatie...</a>

    <b-alert show v-for="(error, index) in fieldErrors" :key="index" variant="danger" v-html="error">
    </b-alert>

    <b-alert show v-for="(error, index) in fieldWarnings" :key="index" variant="danger" v-html="error">
    </b-alert>

    <b-form-checkbox
      v-if="showAutoCasing"
      :id="inputId + '-autoCasing'"
      v-model="autoCasing"
    >
      Automatisch standaard hoofdlettergebruik toepassen
    </b-form-checkbox>

    <page-snippet v-if="showAutoCasing && !autoCasing" name="hoofdletters"></page-snippet>

    <a v-if="copyToTracks" href="#" @click.prevent="$emit('copyToTracks', section, field)">Toepassen op alle tracks</a>
  </div>
</template>

<script>
  import ReleaseConceptHelper from '@/mixins/ReleaseConceptHelper'
  import { toTitleCase } from '@/functions'

  export default {
    name: 'ReleaseConceptField',
    components: {
      'vue-multiselect': () => import('vue-multiselect'),
      'page-snippet': () => import('@/components/PageSnippet'),
    },
    mixins: [
      ReleaseConceptHelper
    ],
    data () {
      return {
        
      }
    },
    props: {
      model: Object,
      defs: Object,
      name: String,
      errors: Object,
      disabled: {
        type: Boolean,
        default: false,
      },
      date: {
        type: Boolean,
        default: false,
      },
      boolean: {
        type: Boolean,
        default: false
      },
      copyToTracks: {
        type: Boolean,
        default: false
      },
      readonly: {
        type: Boolean,
        default: false,
      },
    },
    watch: {
      model () {
        this.loadDefault()
      }
    },
    computed: {
      fieldErrors () {
        if (!this.errors || !this.errors[this.name]) return []
        return this.errors[this.name]
      },
      fieldWarnings () {
        let warnings = []
        let value = this.textValue

        if (['Title', 'Attribution'].includes(this.name) && value) {
          let numCaps = value.replace(/[^A-Z]/g, '').length
          let numNormal = value.replace(/[^a-z]/g, '').length
          if ((numCaps+numNormal > 3) && (numCaps >= numNormal)) {
            warnings.push('De waarde bevat 50% of meer hoofdletters, dat is vaak niet de bedoeling.')
          }
        }

        return warnings
      },
      showAutoCasing () {
        return ['Title'].includes(this.name)
      },
      groupId () { return this.name + '-group'; },
      inputId () { return this.name + '-input'; },
      autoCasing: {
        get() {
          return this.getAttribute(this.model, 'OverrideDefaultCasing') == '1'
        },
        set(v) {
          this.setAttribute(this.model, 'OverrideDefaultCasing', v ? '1' : '0')
        },
      },
      autoCasingOld: {
        get() {
          if (!this.model.AutoCasing) return false
          return this.model.AutoCasing.includes(this.name)
        },
        set(v) {
          let atc
          if (this.model.AutoCasing)
            atc = [...this.model.AutoCasing]
          else
            atc = []
          
          if (v && !atc.includes(this.name)) {
            atc.push(this.name)
          } else if (!v) {
            atc = atc.filter(i => i != this.name)
          }

          this.$set(this.model, 'AutoCasing', atc)
          this.textValue = this.textValue + ''
        },
      },
      inputType () {
        if (this.name == 'Tags.OtherInfluences') {
          return 'tag-text'
        }
        if (this.section == 'Attributes') {
          if (this.date)
            return 'attribute-datepicker'

          if (this.def.Options.length > 0)
            return 'attribute-select'

          return 'attribute-text'
        }

        if (this.section == 'Tags')
          return 'tag-select'

        if (this.def.Options.length > 0)
          return 'select'

        if (this.date)
          return 'datepicker'

        return 'text'
      },
      section () {
        let parts = this.name.split('.')
        if (parts.length == 2) {
          return parts[0]
        }
        return null
      },
      field () {
        let parts = this.name.split('.')
        if (parts.length == 1) {
          return this.name
        }
        return parts[1]
      },
      def () { 
        if (!this.defs[this.name]) {
          console.log('Unable to find definition of ' + this.name)
          return false
        }
        return this.defs[this.name]
      },
      attributeValue: {
        get () {
          if (!this.model.Attributes) return null
          let out = ''
          this.model.Attributes.forEach(attr => {
            if (attr.Type == this.field)
              out = attr.Value
          })
          return out
        },
        set (val) {
          let newAttrs = []
          if (val) {
            newAttrs.push({
              Type: this.field,
              Value: val
            });
          }

          if (this.model.Attributes) {
            this.model.Attributes.forEach(attr => {
              // Only re-add attributes other than current attribute
              if (attr.Type != this.field)
                newAttrs.push(attr)
            })
          }
          this.$set(this.model, 'Attributes', newAttrs)
          this.$emit('change');
        },
      },
      tagText: {
        get () {
          if (this.tagValue == null) return ''
          return this.tagValue.join('\n')
        },
        set (val) {
          val = val.replace('\r', '').split('\n').filter(o => {
            return o.trim() != ''
          })

          val = val.map(o => {
            return { value: o }
          })
          
          this.tagValue = val
          this.$emit('change');
        }
      },
      tagValue: {
        get () {
          if (!this.model.Tags) return null

          if (this.def.MaxOccurs == 1) {
            let out = ''
            this.model.Tags.forEach(tag => {
              if (tag.Type == this.field)
                out = tag.Value
            })
            return out
          } else {
            let out = []
            this.model.Tags.forEach(tag => {
              if (tag.Type == this.field) {
                if (this.inputType != 'tag-text') {
                  out.push(this.selectOptions.find(o => o.value == tag.Value))
                } else {
                  out.push(tag.Value)
                }
              }
            })
            return out
          }
        },
        set (val) {
          console.log('Setting tag (old)', this.field, val)
          let newTags = []
          
          if (val) {
            if (!Array.isArray(val)) {
              newTags.push({
                Type: this.field,
                Value: val
              });
            } else {
              newTags = newTags.concat(val.map(v => { return { Type: this.field, Value: v.value } }))
            }
          }

          if (this.model.Tags) {
            this.model.Tags.forEach(tag => {
              // Only re-add tags other than current tag
              if (tag.Type != this.field)
                newTags.push(tag)
            })
          }

          this.$set(this.model, 'Tags', newTags)
          this.$emit('change');
        },
      },
      textValue: {
        get () {
          let val = this.model[this.field] || null
          if (val != null && this.boolean)
            return val ? '1' : '0'
          return val
        },
        set (val) {
          let orig = val
          let elem = this.$refs[this.inputId]

          if (elem) {
            // store current positions in variables
            var start = elem.selectionStart,
                end = elem.selectionEnd
          }

          if (this.boolean)
            val =  (val == 1 || val == '1' || val === true)
          else if (this.autoCasing && ['Title'].includes(this.name))
            val = toTitleCase(val, this.model.TitleLanguage)
            
          this.$set(this.model, this.field, val)
          this.$emit('change');

          // restore from variables when changed
          if (elem && val != orig) {
            this.$nextTick(() => {
              elem.setSelectionRange(start, end)
            })
          }
         },
      },
      selectOptions () {
        let options = [];

        this.def.Options.forEach(option => {
          if (option.LinkedType && this.model.Tags) {
            // Only for MainGenre. Linked Tag will look up 
            // maingenre, and only show SubGenres which are linked
            let linked_tag = null
            this.model.Tags.forEach(tag => {
              if (tag.Type == option.LinkedType) {
                linked_tag = tag
              }
            })

            if (!linked_tag || linked_tag.Value != option.LinkedValue)
              return
          }

          let optionlabel = option.Value
          if (option.Description) {
            optionlabel = optionlabel + ' | ' + option.Description + ''
          }

          options.push({
            value: option.JsonValue,
            text: optionlabel,
          })
        })
        return options
      }
    },
    methods: {
      loadDefault () {
        if (this.def.DefaultValue) {

          if (!this.section || this.section == 'Rights') {
            if (!this.textValue) {
              this.textValue = this.def.DefaultValue
            }
          }

          if (this.section == 'Tags') {
            if (!this.getTag(this.model, this.field)) {
              this.setTag(this.model, this.field, this.def.DefaultValue)
            }
          }
          
          if (this.section == 'Attributes') {
            if (!this.getAttribute(this.model, this.field)) {
              this.setAttribute(this.model, this.field, this.def.DefaultValue)
            }
          }
        }
      },
    },
    mounted () {
      this.loadDefault()
    }
  }
</script>

<style lang="scss">
  .form-group.required {
    & > label::after {
      content: ' *';
      color: red;
    }
  }

  .kblink {
    position: absolute;
    right: 0;
    top: 0;
  }
</style>