var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { position: "relative" } },
    [
      _vm.def
        ? _c(
            "b-form-group",
            {
              class: { required: _vm.def.Mandatory == 1, "mt-4": true },
              attrs: {
                id: _vm.groupId,
                label: _vm.def.Title,
                "label-for": _vm.inputId,
                description: _vm.def.Description
              }
            },
            [
              _vm.inputType == "readonly"
                ? _c("div", { attrs: { id: _vm.inputId } }, [
                    _vm._v(" " + _vm._s(_vm.textValue) + " ")
                  ])
                : _vm._e(),
              _vm.inputType == "datepicker"
                ? _c("b-form-datepicker", {
                    attrs: { id: _vm.inputId },
                    model: {
                      value: _vm.textValue,
                      callback: function($$v) {
                        _vm.textValue = $$v
                      },
                      expression: "textValue"
                    }
                  })
                : _vm._e(),
              _vm.inputType == "text"
                ? _c("b-form-input", {
                    ref: _vm.inputId,
                    attrs: {
                      id: _vm.inputId,
                      type: "text",
                      disabled: _vm.readonly || _vm.disabled,
                      required: _vm.def.Mandatory == 1
                    },
                    model: {
                      value: _vm.textValue,
                      callback: function($$v) {
                        _vm.textValue = $$v
                      },
                      expression: "textValue"
                    }
                  })
                : _vm._e(),
              _vm.inputType == "select"
                ? _c("b-form-select", {
                    attrs: {
                      id: _vm.inputId,
                      options: _vm.selectOptions,
                      required: _vm.def.Mandatory == true
                    },
                    model: {
                      value: _vm.textValue,
                      callback: function($$v) {
                        _vm.textValue = $$v
                      },
                      expression: "textValue"
                    }
                  })
                : _vm._e(),
              _vm.inputType == "tag-select" && _vm.def.MaxOccurs == 1
                ? _c("b-form-select", {
                    attrs: {
                      id: _vm.inputId,
                      options: _vm.selectOptions,
                      required: _vm.def.Mandatory == true
                    },
                    model: {
                      value: _vm.tagValue,
                      callback: function($$v) {
                        _vm.tagValue = $$v
                      },
                      expression: "tagValue"
                    }
                  })
                : _vm._e(),
              _vm.inputType == "tag-text" && _vm.def.MaxOccurs > 1
                ? _c("b-form-textarea", {
                    attrs: {
                      id: _vm.inputId,
                      required: _vm.def.Mandatory == true
                    },
                    model: {
                      value: _vm.tagText,
                      callback: function($$v) {
                        _vm.tagText = $$v
                      },
                      expression: "tagText"
                    }
                  })
                : _vm._e(),
              _vm.inputType == "tag-select" && _vm.def.MaxOccurs > 1
                ? _c("vue-multiselect", {
                    attrs: {
                      multiple: true,
                      id: _vm.inputId,
                      label: "text",
                      "track-by": "value",
                      placeholder: "Kies één of meer opties",
                      options: _vm.selectOptions,
                      required: _vm.def.Mandatory == true
                    },
                    model: {
                      value: _vm.tagValue,
                      callback: function($$v) {
                        _vm.tagValue = $$v
                      },
                      expression: "tagValue"
                    }
                  })
                : _vm._e(),
              _vm.inputType == "attribute-datepicker"
                ? _c("b-form-datepicker", {
                    attrs: { id: _vm.inputId },
                    model: {
                      value: _vm.attributeValue,
                      callback: function($$v) {
                        _vm.attributeValue = $$v
                      },
                      expression: "attributeValue"
                    }
                  })
                : _vm._e(),
              _vm.inputType == "attribute-text"
                ? _c("b-form-input", {
                    attrs: {
                      id: _vm.inputId,
                      type: "text",
                      required: _vm.def.Mandatory == 1
                    },
                    model: {
                      value: _vm.attributeValue,
                      callback: function($$v) {
                        _vm.attributeValue = $$v
                      },
                      expression: "attributeValue"
                    }
                  })
                : _vm._e(),
              _vm.inputType == "attribute-select"
                ? _c("b-form-select", {
                    attrs: {
                      id: _vm.inputId,
                      options: _vm.selectOptions,
                      required: _vm.def.Mandatory == true
                    },
                    model: {
                      value: _vm.attributeValue,
                      callback: function($$v) {
                        _vm.attributeValue = $$v
                      },
                      expression: "attributeValue"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.def.KBLink
        ? _c(
            "a",
            {
              staticClass: "kblink",
              attrs: { href: _vm.def.KBLink, target: "_blank" }
            },
            [_vm._v("Meer Informatie...")]
          )
        : _vm._e(),
      _vm._l(_vm.fieldErrors, function(error, index) {
        return _c("b-alert", {
          key: index,
          attrs: { show: "", variant: "danger" },
          domProps: { innerHTML: _vm._s(error) }
        })
      }),
      _vm._l(_vm.fieldWarnings, function(error, index) {
        return _c("b-alert", {
          key: index,
          attrs: { show: "", variant: "danger" },
          domProps: { innerHTML: _vm._s(error) }
        })
      }),
      _vm.showAutoCasing
        ? _c(
            "b-form-checkbox",
            {
              attrs: { id: _vm.inputId + "-autoCasing" },
              model: {
                value: _vm.autoCasing,
                callback: function($$v) {
                  _vm.autoCasing = $$v
                },
                expression: "autoCasing"
              }
            },
            [_vm._v(" Automatisch standaard hoofdlettergebruik toepassen ")]
          )
        : _vm._e(),
      _vm.showAutoCasing && !_vm.autoCasing
        ? _c("page-snippet", { attrs: { name: "hoofdletters" } })
        : _vm._e(),
      _vm.copyToTracks
        ? _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.$emit("copyToTracks", _vm.section, _vm.field)
                }
              }
            },
            [_vm._v("Toepassen op alle tracks")]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }